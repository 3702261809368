export const appRootId = "pathapp"

export const appBaseURL = "/"

export const appBuildAssetsDir = "cncclient/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"},{"name":"msapplication-TileColor","content":"#5d6770"},{"name":"theme-color","content":"#5d6770"}],"link":[{"rel":"icon","type":"image/svg+xml","href":"/logo.svg"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","color":"#9f262c"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"manifest","href":"/site.webmanifest"}],"style":[],"script":[],"noscript":[{"innerHTML":"<img src=\"https://www.milmed.ai/b/js/?idsite=1&rec=1\" style=\"border: 0\" alt=\"\" />"}],"title":"Cancer Not Cancer","htmlAttrs":{"lang":"en"}}

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#pathapp'

export const viewTransition = false